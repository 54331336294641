import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { WHITE } from "../../../styles/colors";
import { TABLET_768 } from "../../../styles/sizes";

export const NavContainer = styled.div<INavContainerProps>`
  font-family: "OpenSans";
  transition: ${({ scrolled }) => (scrolled ? "background 0.5s" : "none")};
  background: ${({ scrolled, backgroundColor }) =>
    backgroundColor && scrolled
      ? backgroundColor
      : scrolled
      ? WHITE
      : "transparent"};
  box-shadow: ${({ scrolled }) =>
    scrolled ? "0px 0px 15px rgba(0, 0, 0, 0.15)" : "none"};
  height: ${({ scrolled }) => (scrolled ? "66px" : "75px")};
  padding-top: ${({ scrolled }) => (scrolled ? "0" : "15px")};
  position: fixed;
  width: 100vw;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
`;
interface INavContainerProps {
  scrolled?: boolean;
  backgroundColor?: string;
  initialTextColor?: string;
}

export const Nav = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div``;

export const LogoContainer = styled.a<IRedirectLogo>`
  min-width: 50%;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.privacyPolicy && "min-width: 0;"}
`;

export const PageLogo = styled.img`
  width: 50px;
`;

export const ButtonGroup = styled.div<IButtonGroupProps>`
  width: 50%;
  text-align: ${({ side }) => side};
  line-height: 59px;
  display: ${(props) => (props.privacyPolicy ? "none" : "flex")};
  align-items: center;
  justify-content: ${(props) => props.justify || "center"};
  @media (max-width: 990px) {
    display: none;
  }
`;
interface IButtonGroupProps {
  justify: string;
  side?: string;
  privacyPolicy?: boolean;
}

export const RedirectLogo = styled.div<IRedirectLogo>`
  ${(props) =>
    props.privacyPolicy &&
    "display: flex; justify-content: center; width: 100%;"}
`;

interface IRedirectLogo {
  privacyPolicy?: boolean;
}

export const RedirectLink = styled.a<IMenuAnchorLinkProps>`
  font-family: "OpenSans";
  font-variation-settings: "wght" 600;
  text-decoration: none;
  color: ${({ scrolled, color, initialTextColor }) =>
    scrolled && color === "black"
      ? "white"
      : scrolled
      ? "rgb(65, 65, 65)"
      : initialTextColor};
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  display: inline-block;
  margin: 0 1.22rem;
  position: relative;

  & ::before {
    content: "";
    background: red;
    height: 3px;
    width: 0px;
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 18px;
    transition: 0.25s;
    opacity: 0;
    // transform: translateX(100%);
  }

  :focus,
  :focus-within,
  :focus-visible,
  &:hover {
    outline: none;
    &::before {
      opacity: 1;
      left: 0;
      width: 100%;
      // transform: scale3d(1,1,1);
    }
  }
`;

export const MenuAnchorLink = styled(AnchorLink)<IMenuAnchorLinkProps>`
  font-family: "OpenSans";
  font-variation-settings: "wght" 600;
  text-decoration: none;
  color: ${({ scrolled, color, initialTextColor }) =>
    scrolled && color === "black"
      ? "white"
      : scrolled
      ? "rgb(65, 65, 65)"
      : initialTextColor};
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  display: inline-block;
  margin: 0 1.22rem;
  position: relative;

  & ::before {
    content: "";
    background: red;
    height: 3px;
    width: 0px;
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 18px;
    transition: 0.25s;
    opacity: 0;
    // transform: translateX(100%);
  }

  :focus,
  :focus-within,
  :focus-visible,
  &:hover {
    outline: none;
    &::before {
      opacity: 1;
      left: 0;
      width: 100%;
      // transform: scale3d(1,1,1);
    }
  }
  &:visited {
    color: ${({ scrolled, color, initialTextColor }) =>
      scrolled && color === "black"
        ? "white"
        : scrolled
        ? "rgb(65, 65, 65)"
        : initialTextColor};
  }
`;

interface IMenuAnchorLinkProps {
  scrolled?: boolean;
  onClick?: () => void;
  initialTextColor?: string;
  color?: string;
}

export const Hamburger = styled.div<IHamburgerProps>`
  display: none;
  flex-direction: column;
  align-self: center;
  padding: 9px 0;
  span {
    height: 2px;
    width: 22px;
    background: ${({ scrolled, initialTextColor, backgroundColor }) =>
      scrolled
        ? backgroundColor === "black"
          ? "white "
          : "#414141"
        : initialTextColor};
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: 990px) {
    display: ${(props) => (props.privacyPolicy ? "none" : "flex")};
  }
`;
interface IHamburgerProps {
  scrolled?: boolean;
  backgroundColor?: string;
  initialTextColor?: string;
  privacyPolicy?: boolean;
}

export const MobileMenu = styled.div<IMobileMenuProps>`
  /* position: fixed;
  z-index:100;
  top: 0;
  left: 0;
  right: 0; */
  transition: opacity 0.25s ease-in-out;
  width: 100vw;
  height: ${({ isOpen }) => (isOpen ? "300px" : "0px")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  max-height: 100vh;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  padding: ${({ isOpen }) => (isOpen ? "110px 0 175px 0" : "0")};
  z-index: -1;
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: 1fr;
  }
`;
interface IMobileMenuProps {
  isOpen: boolean;
}

export const MobileMenuLink = styled(MenuAnchorLink)`
  font-size: 2rem;
  margin: auto;
  color: rgb(65, 65, 65);
  padding: 30px 0;
  @media (max-width: ${TABLET_768}px) {
    font-size: 1.5rem;
    padding: 20px 0;
  }
`;

export const MobileMenuRedirectLink = styled(RedirectLink)`
  font-size: 2rem;
  margin: auto;
  color: rgb(65, 65, 65);
  padding: 30px 0;
  @media (max-width: ${TABLET_768}px) {
    font-size: 1.5rem;
    padding: 20px 0;
  }
`;
interface MenuProps {
  color: string;
}
