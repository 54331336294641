import React, { useState, useEffect, useContext } from "react";

import {
  NavContainer,
  Nav,
  LogoContainer,
  PageLogo,
  ButtonGroup,
  MenuAnchorLink,
  Hamburger,
  MobileMenu,
  MobileMenuLink,
  ButtonContainer,
  RedirectLink,
  MobileMenuRedirectLink,
  RedirectLogo,
} from "./styles";

import { useIntl } from "gatsby-plugin-intl";

import { Container } from "../../../styles/commons";

import { MouseContext } from "../../../context/mouseContext";

import Logo from "../../../images/logo_red_psh.svg";

export const Topbar: React.FC<IProps> = ({
  initialTextColor,
  landing,
  backgroundColor,
  privacyPolicy
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolledPercent, setScrolledPercent] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY < 100) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }

      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const newScrollPercentage = (scrollPosition / maxScroll) * 100;

      setScrolledPercent(newScrollPercentage);


      
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrolled, isOpen]);

  const intl = useIntl();

  return (
    <NavContainer
      initialTextColor={initialTextColor}
      scrolled={scrolled}
      style={{}}
      backgroundColor={backgroundColor}
    >
      {scrolled &&
        <div style={{transition: 'width 0.05s ease-im-out',position: 'absolute', top: '0px', height: '2px', background: 'red', width: `${scrolledPercent}%`}} />
      }
      <Container>
        <Nav>
          <ButtonGroup justify="flex-start" privacyPolicy={privacyPolicy}>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            >
              <MenuAnchorLink
                to={"/#hero"}
                initialTextColor={initialTextColor}
                scrolled={scrolled}
                color={backgroundColor}
              >
                {intl.formatMessage({ id: "home" })}
              </MenuAnchorLink>
            </ButtonContainer>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            >
              <MenuAnchorLink
                to={"/#scrollSection"}
                initialTextColor={initialTextColor}
                scrolled={scrolled}
                color={backgroundColor}
              >
                {intl.formatMessage({ id: "services" })}
              </MenuAnchorLink>
            </ButtonContainer>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            >
              <RedirectLink
                href={"/work"}
                initialTextColor={initialTextColor}
                scrolled={scrolled}
                color={backgroundColor}
              >
                {intl.formatMessage({ id: "work" })}
              </RedirectLink>
            </ButtonContainer>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            >
              <RedirectLink
                href={"/about-us"}
                initialTextColor={initialTextColor}
                scrolled={scrolled}
                color={backgroundColor}
              >
                {intl.formatMessage({ id: "culture" })}
              </RedirectLink>
            </ButtonContainer>
          </ButtonGroup>

          <RedirectLogo privacyPolicy={privacyPolicy}>
            <LogoContainer href="/" privacyPolicy={privacyPolicy}>
              <PageLogo src={Logo} />
            </LogoContainer>
          </RedirectLogo>

          <ButtonGroup justify="flex-end" privacyPolicy={privacyPolicy}>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            >
              <MenuAnchorLink
                to={"/#awards"}
                initialTextColor={initialTextColor}
                scrolled={scrolled}
                color={backgroundColor}
              >
                {intl.formatMessage({ id: "awards" })}
              </MenuAnchorLink>
            </ButtonContainer>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            ></ButtonContainer>
            <ButtonContainer
              onMouseEnter={() => {
                cursorChangeHandler("hover");
              }}
              onMouseLeave={() => {
                cursorChangeHandler("hoverOff");
              }}
            >
              <MenuAnchorLink
                to={
                  landing && landing === "work"
                    ? `/${landing}/#contact${landing}`
                    : `/#contacthome`
                }
                initialTextColor={initialTextColor}
                scrolled={scrolled}
                color={backgroundColor}
              >
                {intl.formatMessage({ id: "contact_us" })}
              </MenuAnchorLink>
            </ButtonContainer>
          </ButtonGroup>

          <Hamburger
            privacyPolicy={privacyPolicy}
            onClick={() => setIsOpen(!isOpen)}
            scrolled={isOpen ? true : scrolled}
            initialTextColor={initialTextColor}
            backgroundColor={backgroundColor}
          >
            <span />
            <span />
            <span />
            <span hidden>Menu</span>
          </Hamburger>
        </Nav>
      </Container>

      <MobileMenu isOpen={isOpen}>
        <div onClick={() => setIsOpen(false)}>
          <MobileMenuLink
            to={"/#hero"}
            scrolled={true}
            
          >
            {intl.formatMessage({ id: "home" })}
          </MobileMenuLink>
        </div>
        <div onClick={() => setIsOpen(false)}>
          <MobileMenuLink
            to={"/#awards"}
            scrolled={true}
          >
            {intl.formatMessage({ id: "awards" })}
          </MobileMenuLink>
        </div>
        <div onClick={() => setIsOpen(false)}>
          <MobileMenuLink
            to={"/#scrollSection"}
            scrolled={true}
          >
            {intl.formatMessage({ id: "services" })}
          </MobileMenuLink>
        </div>
        <MobileMenuRedirectLink href={"/work"}>
          {intl.formatMessage({ id: "work" })}
        </MobileMenuRedirectLink>
        <MobileMenuRedirectLink href={"/about-us"}>
          {intl.formatMessage({ id: "culture" })}
        </MobileMenuRedirectLink>
        <div onClick={() => setIsOpen(false)}>
          <MobileMenuLink
            to={
              landing && landing === "work"
                ? `/${landing}/#contact${landing}`
                : `/#contacthome`
            }
            scrolled={scrolled}
          >
            {intl.formatMessage({ id: "contact_us" })}
          </MobileMenuLink>
        </div>
      </MobileMenu>
    </NavContainer>
  );
};

interface IProps {
  initialTextColor: string | "white";
  landing: string | "home";
  backgroundColor: string | "";
  privacyPolicy?: boolean;
}
