import styled from 'styled-components'
import { MOBILE_500, TABLET_740, TABLET_768 } from './sizes';

export const Container = styled.div<IContainerProps>`
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 10;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
  padding-top: ${(props) => props.paddingTop || 0};
  padding-bottom: ${(props) => props.paddingBottom || 0};
  text-align:center;
  max-width: 1200px;
  min-width: min(40vw, 1600px);
  width: 90%;
  @media (max-width: 990px){
    padding: 0 15px;
    margin-top: ${(props) => props.marginTopMobile || 0};
    margin-bottom: ${(props) => props.marginBottomMobile || 0};
    padding-top: ${(props) => props.paddingTopMobile || 0};
    padding-bottom: ${(props) => props.paddingBottomMobile || 0};
    
  }
`

export const LegalContainer = styled.div<IContainerProps>`
  margin: 0 auto;
  margin-top: 150px;
  min-height: 90vh;
  max-width: 1200px;
  padding-bottom: 100px;
  width: 90%;
  @media (max-width: 1920px){
  }
  @media (max-width: 1100px){
    width: 90%;
    margin-top: 150px;
  }
  @media (max-width: ${MOBILE_500}px){
    width: 95%;
    margin-top: 150px;
  }


`

export const CultureContainer = styled.div<IContainerProps>`
  margin: 0 auto;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
  padding-top: ${(props) => props.paddingTop || 0};
  padding-bottom: ${(props) => props.paddingBottom || 0};
  max-width: 1200px;
  min-width: min(40vw, 1600px);
  width: 90vw;
  @media (max-width: 1920px){
  }
  @media (max-width: 1100px){
    margin-top: ${(props) => props.marginTopTablet || 0};
    margin-bottom: ${(props) => props.marginBottomTablet || 0};
    padding-top: ${(props) => props.paddingTopTablet || 0};
    padding-bottom: ${(props) => props.paddingBottomTablet || 0};
  }
  @media (max-width: ${MOBILE_500}px){
    width: 100%;
    margin-top: ${(props) => props.marginTopMobile || '0'};
    margin-bottom: ${(props) => props.marginBottomMobile || '0'};
    padding-top: ${(props) => props.paddingTopMobile || '20px'};
    padding-bottom: ${(props) => props.paddingBottomMobile || '20px'};
  }


`

export const SubtitleCulture = styled.div<ITitleProps>`
  width: 100%;
  margin: 140px 0 35px;
  color: ${(props) => props.fontColor};
  font:  52px/42px 'Montserrat';
  text-align: left;
  @media (max-width: 1023px) {
    margin: 0;
    align-self: center;
    text-align: center;
    padding-bottom: 25px;
  }

  @media (max-width: 500px) {
    margin: 10px 0;
    align-self: flex-start;
    text-align: left;
  }
  @media (max-width: 380px) {
    font-size: 32px;
  }
`

export const BackgroundWrapper = styled.div<IBackgroundWrapperProps>`
  background: ${props => props.background};
`

interface IBackgroundWrapperProps {
  background: string
}

interface ITitleProps {
  fontColor?: string
}

interface IContainerProps {
  marginTop?: string,
  marginBottom?: string,
  paddingTop?: string,
  paddingBottom?: string,
  marginTopMobile?: string,
  marginBottomMobile?: string,
  paddingTopMobile?: string,
  paddingBottomMobile?: string,
  marginTopTablet?: string,
  marginBottomTablet?: string,
  paddingTopTablet?: string,
  paddingBottomTablet?: string,
}